function sendReasonCancel(cod = null) {
    $("#btn-cancel-subscription").button('loading');
    var data = $("#formSubscriptionCancel").serialize();
    var url = $("#formSubscriptionCancel").attr('action');
    var reason = $("input[name=reason_client]").val();

    if (reason.length < 20) {
        $("#btn-cancel-subscription").button('reset');
        $('#modal-reason').show().addClass('open');
        return false;
    }

    $.post(url, data, function (response) {
        reason = cod ? 'Cod #' + cod + reason : reason;
        const linkWhatsapp = 'https://api.whatsapp.com/send?phone=5511943452099?&text=' + reason;
        window.open(linkWhatsapp, '_blank');
        $("#whatsapp-url").attr('href', linkWhatsapp);
        $("#btn-cancel-subscription").button('reset');
        $('#modal-suspend').hide().removeClass('open');
        $('#modal-cancel-subscription-success').show().addClass('open');
    }).fail(function (error) {
        Swal.fire(
            'Error! Try again later!',
            'warning'
        )
        $("#btn-cancel-subscription").button('reset');
    });

}

/**
 * Prevent submit
 */
$("#formSubscriptionCancel").submit(function (e) {
    e.preventDefault();
    var cod = $("input[name=cod_cancel]").val();
    sendReasonCancel(cod);
})